html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
}

.mdc-list-item--selected,
.mdc-list-item--activated {
  background-color: black;
  color: #fff !important;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before,
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:hover::before {
  opacity: 0 !important;
}
.App {
  height: 100vh;
}
